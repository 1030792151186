import { Card, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Notification, NotificationType } from "../../utils/notification";
import useDataFetcher from "../../utils/dataFetcher";
import {
  ChartCollumnsSingle,
  IChartSingleCollumnsData,
} from "../../components/chart/ChartSingleCollumns";
import { GetStats } from "../../utils/request";
const { TabPane } = Tabs;

export const LayoutMyStats: React.FunctionComponent<any> = (args: any) => {
  var progress = args["type"];

  const { isFetching, fetchData } = useDataFetcher();

  const [dataSourceMonth, setDataSourceMonth] = useState(
    [] as IChartSingleCollumnsData[]
  );

  const [dataSourceYear, setDataSourceYear] = useState(
    [] as IChartSingleCollumnsData[]
  );

  const [dataSourceMonthMoney, setDataSourceMonthMoney] = useState(
    [] as IChartSingleCollumnsData[]
  );

  const [dataSourceYearMoney, setDataSourceYearMoney] = useState(
    [] as IChartSingleCollumnsData[]
  );

  function getList(data: []) {
    const list = [] as IChartSingleCollumnsData[];
    if (data) {
      data.forEach((element: string) => {
        var splt = element.split("#");
        list.push({
          type: splt[0],
          value: splt[1] as unknown as number,
        });
      });
    }
    return list;
  }

  useEffect(() => {
    fetchData(progress == "global" ? GetStats("global") : GetStats("me"), {
      onResolve(data: []) {
        setDataSourceMonth(getList(data["amountMonth"]));
        setDataSourceYear(getList(data["amountYear"]));
        setDataSourceMonthMoney(getList(data["moneyMonth"]));
        setDataSourceYearMoney(getList(data["moneyYear"]));
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }, []);

  return (
    <div id="charts" className="flex flex-col space-y-4">
      <Card title="Satın Alım (Miktar)">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Bu Ay" key="month">
            <ChartCollumnsSingle
              valueMoneyFormat={false}
              loading={isFetching}
              data={dataSourceMonth}
            />
          </TabPane>
          <TabPane tab="Bu Yıl" key="year">
            <ChartCollumnsSingle
              valueMoneyFormat={false}
              loading={isFetching}
              data={dataSourceYear}
            />
          </TabPane>
        </Tabs>
      </Card>

      <Card title="Satın Alım (TL)">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Bu Ay" key="month">
            <ChartCollumnsSingle
              loading={isFetching}
              data={dataSourceMonthMoney}
              valueMoneyFormat={true}
            />
          </TabPane>
          <TabPane tab="Bu Yıl" key="year">
            <ChartCollumnsSingle
              loading={isFetching}
              data={dataSourceYearMoney}
              valueMoneyFormat={true}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};
