import moment from "moment";

function formatDate(date: Date) {
  return moment(date).format("DD.MM.YYYY HH:mm:ss");
}

export const urlToPageHeaderTitle = (url: string) => {
  //console.log(url);
  if (url.indexOf("myorders") > -1) return "Siparişlerim";
  else if (url.indexOf("mystats") > -1) return "İstatistiklerim";
  else if (url.indexOf("admin/settings") > -1) return "Ayarlar";
  else if (url.indexOf("admin/stats") > -1) return "İstatistikler";
  else if (url.indexOf("admin/orders") > -1) return "Siparişler";
  else if (url.indexOf("admin/users") > -1) return "Kullanıcılar";
  else if (url.indexOf("admin/users") > -1) return "Kullanıcılar 2";
  else if (url.indexOf("stock/options") > -1) return "Stok Ayarları";
  else if (url.indexOf("stock/list") > -1) return "Stok Listesi";
};
