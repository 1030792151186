import { Button, Form, Input, Select } from "antd";
import { InputCurrency } from "../input/InputCurrency";
import { lowerCase } from "../../utils/textutils";
import { FormInstance } from "antd/es/form";

const { Option } = Select;

export interface ModelFormCreateOrder {
  form: FormInstance;
  onFormFinish: (editingOrderId: string, values: any) => void;
  closeForm: () => void;
  options: any[];
  editingOrderId: string;
}

export const FormCreateOrder: React.FunctionComponent<ModelFormCreateOrder> = (
  props: ModelFormCreateOrder
) => {
  return (
    <Form
      requiredMark={false}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      // initialValues={{ size: componentSize }}
      // onValuesChange={onFormLayoutChange}
      //size={"middle"}
      form={props.form}
      onFinish={(values: any) => {
        props.onFormFinish(props.editingOrderId, values);
      }}
      /*onFinishFailed={(e) => {
        console.log("", e);
      }}*/
      name="userForm"
    >
      <Form.Item name="orderId" label="Sipariş No" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      {
        <Form.Item name="website" label="Website" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder={"Ara"}
            optionFilterProp="children"
            filterOption={(input, option) => {
              return lowerCase(option!.children as unknown as string).includes(
                lowerCase(input)
              );
            }}
            filterSort={(optionA, optionB) => {
              return (optionA!.children as unknown as string)
                .toLowerCase()
                .localeCompare(
                  (optionB!.children as unknown as string).toLowerCase()
                );
            }}
          >
            {props.options &&
              props.options["websites"] &&
              props.options["websites"].map((item) => (
                <Option key={item}>{item}</Option>
              ))}
          </Select>
        </Form.Item>
      }
      <Form.Item name="seller" label="Satıcı" rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder={"Ara"}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return lowerCase(option!.children as unknown as string).includes(
              lowerCase(input)
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              );
          }}
        >
          {props.options &&
            props.options["sellers"] &&
            props.options["sellers"].map((item) => (
              <Option key={item}>{item}</Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item name="product" label="Ürün" rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder={"Ara"}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return lowerCase(option!.children as unknown as string).includes(
              lowerCase(input)
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              );
          }}
        >
          {props.options &&
            props.options["products"] &&
            props.options["products"].map((item) => (
              <Option key={item}>{item}</Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="color" label="Renk" rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder={"Ara"}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return lowerCase(option!.children as unknown as string).includes(
              lowerCase(input)
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              );
          }}
        >
          {props.options &&
            props.options["colors"] &&
            props.options["colors"].map((item) => (
              <Option key={item}>{item}</Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="company" label="Firma" rules={[{ required: true }]}>
        <Select
          showSearch
          placeholder={"Ara"}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return lowerCase(option!.children as unknown as string).includes(
              lowerCase(input)
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              );
          }}
        >
          {props.options &&
            props.options["companies"] &&
            props.options["companies"].map((item) => (
              <Option key={item}>{item}</Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="paymentType"
        label="Ödeme Türü"
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          placeholder={"Seç"}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return lowerCase(option!.children as unknown as string).includes(
              lowerCase(input)
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              );
          }}
        >
          <Option key="deferred">Vadeli</Option>
          <Option key="cash">Nakit</Option>
        </Select>
      </Form.Item>

      <Form.Item name="desc" label="Açıklama" rules={[{ required: false }]}>
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item name="price" label="Fiyat" rules={[{ required: true }]}>
        <InputCurrency style={{ width: 120 }} value={""} onChange={() => {}} />
      </Form.Item>

      {/*<Form.Item
        name="amount"
        label="Miktar"
        initialValue={1}
        rules={[{ required: true }]}
      >
        <InputNumber name="input-amount" />
      </Form.Item>*/}

      <div className="flex space-x-2 place-content-end">
        <Button onClick={props.closeForm}>Kapat</Button>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
      </div>
    </Form>
  );
};
