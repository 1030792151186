import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { ISelectOptionEntry } from "../../components/select/SelectBasic";
import { moneyFormatter } from "../../components/table/TableOrderList";

import { Image } from "antd";
import logo from "../../dogalogo.png";
import { IStock, IStockCategory, IStockProduct } from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import { Notification, NotificationType } from "../../utils/notification";
import {
  GetStockCategories,
  GetStockList,
  GetStockProducts,
  ViewStockCategoryImage,
} from "../../utils/request";
import { COLORS_AS_SELECT_OPTION, WEEK_DAYS } from "../../utils/utils";
import { WhatsAppOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";

import { toPng } from "html-to-image";
import { Link } from "react-router-dom";

interface ITableData {
  category: IStockCategory;
  entries: ITableEntry[];
}

interface ITableEntry {
  product: IStockProduct;
  price: number;
  colors: string[];
}

function getColorPalette(color: string): string {
  if (color == "bronze") return "rgb(191, 137, 112)";
  else if (color == "hazel") return "rgb(235, 200, 178)";
  else if (color == "gray") return "gray";
  else if (color == "silver") return "silver";
  else if (color == "brown") return "brown";
  else if (color == "red") return "red";
  else if (color == "navyblue") return "rgb(0, 0, 128)";
  else if (color == "blue") return "blue";
  else if (color == "purple") return "purple";
  else if (color == "pink") return "pink";
  else if (color == "yellow") return "yellow";
  else if (color == "black") return "black";
  else if (color == "orange") return "orange";
  else if (color == "sierrablue") return "rgb(105, 171, 206)";
  else if (color == "green") return "green";
  else if (color == "spacegray") return "rgb(113, 115, 120)";
  else if (color == "prismblue") return "rgb(180, 220, 240)";
  return "white";
}

const CONTACT: { key: string; value: string }[] = [
  { key: "KAYHAN", value: "0553 636 88 06" },
  { key: "AYSEL", value: "0536 366 89 24" },
  { key: "DUYGU", value: "0536 366 94 22" },
  { key: "OKAN", value: "0544 415 05 05" },
];

export const LayoutStockPrint: React.FunctionComponent = () => {
  const { isFetching, fetchData } = useDataFetcher();
  const [collumnData, setCollumnData] = useState<any[][]>(undefined);

  useEffect(() => {
    updateDataSources();
  }, []);

  function updateDataSources() {
    fetchData(GetStockProducts(), {
      onResolve(data: []) {
        var productData = [] as IStockProduct[];
        data?.forEach((element) => {
          var product = element as IStockProduct;
          productData[product.id] = product;
        });

        // Get Collumn List
        fetchData(GetStockCategories(), {
          onResolve(categoryData: any[]) {
            // Get Stock List
            fetchData(GetStockList(), {
              onResolve(data: []) {
                var tableDataList = [] as ITableData[];

                categoryData.forEach((element) => {
                  tableDataList[element.id] = {
                    category: element,
                    entries: [],
                  };
                });

                data.forEach((element) => {
                  var stock = element as IStock;
                  var product = productData[stock.productId];
                  if (product && stock.amount > 0) {
                    var tableData = tableDataList[
                      product.categoryId
                    ] as ITableData;
                    if (tableData) {
                      var entryId = (stock.productId +
                        "." +
                        stock.price) as string;

                      if (!tableData.entries[entryId]) {
                        tableData.entries[entryId] = {
                          product: product,
                          price: stock.price,
                          colors: [],
                        };
                      }

                      tableData.entries[entryId].colors.push(stock.color);
                    }
                  }
                });

                var res = [] as any[][];
                res[0] = [];
                res[1] = [];
                //res[2] = [];

                Object.values(tableDataList)
                  .filter((element) => {
                    return Object.values(element.entries).length > 0;
                  })
                  .forEach((element) => {
                    var minIndex = -1;
                    var min = 0;

                    for (let key in res) {
                      if (res[key].length == 0) {
                        minIndex = key as any as number;
                        break;
                      }

                      var colSize = 0;

                      let tableDataList = res[key] as ITableData[];
                      tableDataList.forEach((element) => {
                        var entries = Object.values(element.entries);

                        var add = entries.length + 5;
                        entries.forEach((element) => {
                          if (element.product.displayName.length > 36) {
                            add += 1;
                          }
                        });

                        colSize += entries.length + add;
                      });

                      if (minIndex == -1 || colSize < min) {
                        minIndex = key as any as number;
                        min = colSize;
                      }
                    }

                    if (minIndex == -1) minIndex = 0;

                    res[minIndex].push(element);
                  });

                setCollumnData(res);
              },
              onError(error) {
                console.log(error);
                new Notification(
                  NotificationType.Error,
                  "Stok listesi alınırken hata oluştu"
                )
                  .setDuration(2)
                  .send();
              },
            });
          },
          onError(error) {
            new Notification(
              NotificationType.Error,
              "Kategori listesi alınırken hata oluştu"
            )
              .setDuration(2)
              .send();
          },
        });
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ürün listesi alınırken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  return (
    <div className="flex flex-col">
      {(isFetching || !collumnData) && (
        <div className="flex flex-col items-center">
          <span className="font-bold">Veriler işleniyor...</span>
          <Spin size="large" />
        </div>
      )}

      {!isFetching && collumnData && (
        <>
          <div className="flex m-2">
            <Button
              type="primary"
              onClick={() => {
                /*let box = document.getElementById("content") as any;
                let width = box.offsetWidth;
                let height = box.offsetHeight;*/
                toPng(document.getElementById("content"), {
                  cacheBust: true,
                })
                  .then((dataUrl) => {
                    const link = document.createElement("a");
                    link.download = "dogatechfiyatlistesi.png";
                    link.href = dataUrl;
                    link.click();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Resim Olarak Kaydet
            </Button>
          </div>
          <Content loading={isFetching} collumnData={collumnData} />
        </>
      )}
    </div>
  );
};

interface PropsContent {
  loading: boolean;
  collumnData: any[][];
}

const Content: React.FunctionComponent<PropsContent> = (
  props: PropsContent
) => {
  function getSize(displayName: string): number {
    if (displayName == "APPLE") return 30;
    else if (displayName == "SAMSUNG") return 150;
    else if (displayName == "TCL") return 75;
    else if (displayName == "GENERAL MOBİLE") return 200;
    else if (displayName == "POCO") return 50;
    else if (displayName == "DİJİKİD") return 50;
    else if (displayName == "XIAOMI") return 40;
    return 100;
  }

  return (
    <div
      id="content"
      className="container flex flex-col p-2 space-y-4 max-w-3xl bg-white"
    >
      <Header />

      <div className="flex space-x-4">
        {props.collumnData.map((value: any, index: number) => {
          var tableDataList = value as ITableData[];

          return (
            <div id={"id_" + index} className="flex w-full flex-col space-y-4">
              {tableDataList.map((tableData: ITableData) => {
                {
                  return (
                    <div>
                      <Image
                        width={getSize(tableData.category.displayName)}
                        src={ViewStockCategoryImage(
                          tableData.category.logoPath
                        )}
                      />
                      <Table tableData={tableData} />
                    </div>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Header: React.FunctionComponent = () => {
  var dayOfWeek = new Date().toLocaleString(window.navigator.language, {
    day: "numeric",
    weekday: "long",
    month: "long",
  });

  return (
    <div className="flex p-2 justify-between items-center outline outline-2 outline-teal-400 rounded">
      <div className="flex flex-col">
        <span className="font-bold text-red-600">{dayOfWeek}</span>
        <span>Diriliş Mh. Destegül Sk.</span>
        <span>No:13 Kat 7 Daire 42 Nektower</span>
        <span className="font-bold">Mamak, ANKARA </span>
      </div>
      <div className="flex flex space-x-2 items-center">
        <img
          className="brightness-0"
          style={{
            height: 64,
            width: 64,
          }}
          src={logo}
          alt="logo"
        />
        <span className="font-mono font-bold text-2xl tracking-tighter">
          DOGATECH TEKNOLOJİ
        </span>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {CONTACT.map((arr: any) => {
              return (
                <tr className="text-xs font-bold">
                  <td>
                    <WhatsAppOutlined style={{ paddingBottom: 5 }} />
                  </td>
                  <td>
                    <span style={{ marginRight: 10 }}>{arr["key"]}</span>
                  </td>
                  <td>
                    <span className="text-xs text-red-600">{arr["value"]}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Table: React.FunctionComponent<{ tableData: ITableData }> = (props: {
  tableData: ITableData;
}) => {
  const sort = (n1: ITableEntry, n2: ITableEntry) => {
    if (n1.price > n2.price) return 1;
    if (n1.price < n2.price) return -1;
    return 0;
  };

  return (
    <table className="w-full border-seperate outline outline-2 rounded outline-teal-300">
      <thead className="outline outline-2 rounded outline-teal-400 bg-teal-300">
        <tr className="text-emerald-800">
          <th className="text-left w-4/6 pl-1">
            {props.tableData.category.displayName}
          </th>
          <th className="w-1/6">Renk</th>
          <th className="text-right w-1/6 pr-1">Fiyat</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(props.tableData.entries)
          .sort(sort)
          .map((entry: ITableEntry, index: number) => {
            return (
              <tr
                className={
                  "font-bold text-xs " +
                  (index % 2 == 0
                    ? "bg-slate-200 border-b"
                    : "bg-white border-b")
                }
              >
                <td>{entry.product.displayName}</td>
                <td>
                  <div className="flex space-x-1">
                    {COLORS_AS_SELECT_OPTION.map(
                      (colorEnum: ISelectOptionEntry) => {
                        const found = (element) => element == colorEnum.key;
                        if (!entry.colors.some(found)) return;
                        return (
                          <div
                            style={{
                              backgroundColor: getColorPalette(colorEnum.key),
                            }}
                            className={"w-3 h-3 rounded-full outline outline-1"}
                          />
                        );
                      }
                    )}
                  </div>
                </td>
                <td className="text-right">
                  {moneyFormatter.format(entry.price)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
