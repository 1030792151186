import { Column } from "@ant-design/plots";

export interface IChartSingleCollumnsData {
  type: string;
  value: number;
}

export interface ArgsTableOrderList {
  data: IChartSingleCollumnsData[];
  valueMoneyFormat: boolean;
  loading: boolean;
}

const moneyFormatter = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "TRY",
});

export const ChartCollumnsSingle: React.FunctionComponent<
  ArgsTableOrderList
> = (props: ArgsTableOrderList) => {
  const data = props.data;

  const config = {
    data,
    xField: "type",
    yField: "value",
    meta: {
      /*type: {
        alias: "类别",
      },*/
      value: {
        alias: "Toplam",
        formatter: (value: any, index?: number) => {
          if (props.valueMoneyFormat) return moneyFormatter.format(value);
          return value;
        },
      },
    },
  };

  return <Column loading={props.loading} {...config} />;
};
