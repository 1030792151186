import { DeleteOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import Search from "antd/lib/input/Search";

export interface ArgsListBasicOption {
  title: string;
  data: ListOptionEntry[];
  add(value: string): void;
  remove(value: ListOptionEntry): void;
}

export interface ListOptionEntry {
  id: string;
  displayName: string;
}

export const ListBasicOption: React.FunctionComponent<ArgsListBasicOption> = (
  props: ArgsListBasicOption
) => {
  return (
    <div>
      <Search className="mb-4" enterButton="Ekle" onSearch={props.add} />

      <List
        bordered
        dataSource={props.data}
        style={{ overflow: "auto", /*minWidth: "400px",*/ height: "420px" }}
        renderItem={(item) => (
          <List.Item>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => props.remove(item)}
            ></Button>
            {item.displayName}
          </List.Item>
        )}
      />
    </div>
  );
};
