
const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function createRandomString(length:number) {
    var result = '';
    var charactersLength = chars.length;
    for (var i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}