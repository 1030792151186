import { Card } from "antd";
import React, { useEffect, useState } from "react";

import { ListStockCategories } from "../../components/list/ListStockCategories";
import { ListStockProducts } from "../../components/list/ListStockProducts";
import { IStockCategory, IStockProduct } from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import useDataSender from "../../utils/dataSender";
import { Notification, NotificationType } from "../../utils/notification";
import {
  AddStockCategory,
  AddStockProuct,
  DeleteStockCategory,
  DeleteStockProuct,
  EditStockProuct,
  GetStockCategories,
  GetStockProducts,
} from "../../utils/request";

export const LayoutStockOptions: React.FunctionComponent = () => {
  const { isFetching, fetchData } = useDataFetcher();
  const { isSending, sendData } = useDataSender();

  const [categories, setCategories] = useState([] as IStockCategory[]);
  const [products, setProducts] = useState([] as IStockProduct[]);

  useEffect(() => {
    updateDataSources();
  }, []);

  function updateDataSources() {
    fetchData(GetStockCategories(), {
      onResolve(data: []) {
        setCategories(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockProducts(), {
      onResolve(data: []) {
        data.sort((n1: IStockProduct, n2: IStockProduct) => {
          var n1CategoryId = n1.categoryId as string;
          var n2CategoryId = n2.categoryId as string;

          if (!n1CategoryId) n1CategoryId = "1";
          if (!n2CategoryId) n2CategoryId = "1";

          return n1CategoryId.localeCompare(n2CategoryId);
        });

        setProducts(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Category
  function onAddCategory(value: string, logoFile: any) {
    const formData = new FormData();
    formData.append("name", value);
    formData.append("file", logoFile);

    sendData(AddStockCategory(), formData, {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kategori eklendi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveCategory(id: string) {
    fetchData(DeleteStockCategory(id), {
      onResolve(data: []) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kategori silindi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori silinirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Product
  function onAddProduct(
    editId: string,
    displayName: string,
    category: IStockCategory
  ) {
    var url = AddStockProuct(displayName, category.id);
    if (editId && editId != null)
      url = EditStockProuct(editId, displayName, category.id);

    fetchData(url, {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Kategori ürünü " + (editId ? "düzenlendi" : "eklendi")
        ).send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori ürünü eklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function onRemoveProduct(id: string) {
    fetchData(DeleteStockProuct(id), {
      onResolve(data) {
        updateDataSources();
        new Notification(
          NotificationType.Success,
          "Kategori ürünü silindi"
        ).send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori ürünü eklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  return (
    <div className="flex space-x-4">
      <Card title="Kategoriler" style={{ minWidth: 500 }}>
        <ListStockCategories
          title="Kategoriler"
          loading={isSending || isFetching}
          categories={categories}
          add={onAddCategory}
          remove={onRemoveCategory}
        />
      </Card>
      <Card title="Ürünler" style={{ minWidth: 500 }}>
        <ListStockProducts
          title="Ürünler"
          loading={isSending || isFetching}
          categories={categories}
          products={products}
          add={onAddProduct}
          remove={onRemoveProduct}
        />
      </Card>
    </div>
  );
};
