import { Button, Modal } from "antd";
import React, { useRef } from "react";
import { DownloadOutlined, PrinterFilled } from "@ant-design/icons";
import { DownloadInvoice, ViewInvoice } from "../../utils/request";
import { useReactToPrint } from "react-to-print";

import { PDFRenderer } from "./PDFRenderer";
import { createRandomString } from "../../utils/random";
import { IOrder } from "../../models";

export interface ArgsPDFExportInvoice {
  order: IOrder;
  onCancel: () => void;
  onAfterPrint: (info: IOrder) => void;
}

export const PDFExportInvoice: React.FunctionComponent<ArgsPDFExportInvoice> = (
  props: ArgsPDFExportInvoice
) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => props.onAfterPrint(props.order),
  });

  return (
    <>
      {props.order && (
        <Modal
          title={
            <div className="flex space-x-2">
              <Button
                type={props.order.invoicePrinted ? "primary" : "default"}
                icon={<PrinterFilled />}
                style={
                  props.order.invoicePrinted
                    ? { background: "green", borderColor: "green" }
                    : {}
                }
                onClick={handlePrint}
              ></Button>
              <Button
                onClick={() => {
                  window.open(
                    DownloadInvoice(props.order.invoice),
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
                icon={<DownloadOutlined />}
              ></Button>
              <Button danger type="primary" onClick={props.onCancel}>
                Kapat
              </Button>
            </div>
          }
          width={700}
          footer={null}
          bodyStyle={{
            maxHeight: 720,
            overflowY: "scroll",
            overflowX: "scroll",
          }}
          visible={props.order != null}
          onCancel={props.onCancel}
        >
          {props.order && (
            <PDFRenderer
              key={props.order.invoice + "#" + createRandomString(5)}
              ref={componentRef}
              url={ViewInvoice(props.order.invoice)}
            />
          )}
        </Modal>
      )}
    </>
  );
};
