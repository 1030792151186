import ReactDOM from "react-dom";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import App from "./App";
import "./index.css";
import "antd/dist/antd.min.css";

import { Page404 } from "./pages/404/404";
import { LayoutAdminSettings } from "./pages/admin/settings";
import { LayoutMyOrders } from "./pages/myorders/myorders";
import { LayoutMyStats } from "./pages/mystats/mystats";
import { LayoutLogin } from "./pages/login/login";
import { LayoutUsers } from "./pages/users/users";
import { isOperator } from "./utils/permission";
import jsPDF from "jspdf";
import { jspdfFont } from "./utils/jspdffont";
import { LayoutStockOptions } from "./pages/stock/options";
import { LayoutStockList } from "./pages/stock/list";
import { LayoutStockPrint } from "./pages/stock/stockprint";

var callAddFont = function () {
  this.addFileToVFS("calibri.ttf", jspdfFont);
  this.addFont("calibri.ttf", "calibri", "normal");
};
jsPDF.API.events.push(["addFonts", callAddFont]);

ReactDOM.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LayoutLogin />} />
        <Route path="/stock/print" element={<LayoutStockPrint />} />
        <Route path="/" element={<App />}>
          <Route path="/" element={<Navigate to="/myorders" />} />
          <Route path="/myorders" element={<LayoutMyOrders type="my" />} />
          <Route path="/mystats" element={<LayoutMyStats type="my" />} />
          {isOperator() && (
            <Route path="admin">
              <Route path="orders" element={<LayoutMyOrders type="global" />} />
              <Route path="settings" element={<LayoutAdminSettings />} />
              <Route path="stats" element={<LayoutMyStats type="global" />} />
              <Route path="users" element={<LayoutUsers />} />
            </Route>
          )}
          {isOperator() && (
            <Route path="stock">
              <Route path="options" element={<LayoutStockOptions />} />
              <Route path="list" element={<LayoutStockList />} />
            </Route>
          )}
          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
