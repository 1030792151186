import { Form, Button, Col, Row, Input, Space } from "antd";
import { UserOutlined, UnlockOutlined } from "@ant-design/icons";

export interface ArgsFormRegister {
  onFinish: (values: any) => void;
}

export const FormRegister: React.FunctionComponent<ArgsFormRegister> = (
  props: ArgsFormRegister
) => {
  return (
    <Form layout="vertical" hideRequiredMark onFinish={props.onFinish}>
      <Row>
        <Col>
          <Form.Item
            name="username"
            label="Kullanıcı Adı"
            rules={[{ required: true, message: "Kullanıcı adı gir" }]}
          >
            <Input placeholder="Kullanıcı adı gir" prefix={<UserOutlined />} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="fullName"
            label="İsim Soyisim"
            rules={[{ required: true, message: "İsim soyisim gir" }]}
          >
            <Input placeholder="İsim soyisim gir" prefix={<UserOutlined />} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            name="password"
            label="Şifre"
            rules={[{ required: true, message: "Şifre gir" }]}
          >
            <Input.Password
              placeholder="Şifre gir"
              prefix={<UnlockOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
