import { Button, Col, Form, Input, Row, Space } from "antd";
import { IUserData } from "../../models";
import { UserOutlined } from "@ant-design/icons";

export interface ArgsFormSettings {
  admin: IUserData;
  onFinish: (values: any) => void;
}

export const FormSettings: React.FunctionComponent<ArgsFormSettings> = (
  props: ArgsFormSettings
) => {
  /*const permissions = [];
  AdminPermission.asArray.forEach((element) => {
    permissions.push(element.displayName);
  });

  const defaultPermissions = [];
  props.admin.permissions.forEach((element) => {
    var displayName = AdminPermission.displayNameById[element] as string;
    if (displayName != null) {
      defaultPermissions.push(displayName);
    }
  });*/

  function onFormFinish(values: []) {
    var processedData = [];
    processedData["permissions"] = [];

    var password = values["password"];
    if (password != null) processedData["password"] = password;

    var fullName = values["fullName"];
    if (fullName != null) processedData["fullName"] = fullName;

    /*var permissions = values["permissions"] as [];
    permissions.forEach((element) => {
      var id = AdminPermission.idByDisplayName[element] as number;
      if (id != null) {
        processedData["permissions"].push(id);
      }
    });*/

    props.onFinish(processedData);
  }

  return (
    <Form layout="vertical" hideRequiredMark onFinish={onFormFinish}>
      <Col span={12}>
        <Form.Item name="password" label="Şifre">
          <Input.Password placeholder="Yeni şifre gir" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="fullName"
          label="İsim Soyisim"
          initialValue={props.admin.fullName}
          rules={[{ required: true, message: "İsim soyisim gir" }]}
        >
          <Input placeholder="İsim soyisim gir" prefix={<UserOutlined />} />
        </Form.Item>
      </Col>
      {/*<Row>
        <Divider />
        <Form.Item
          className="w-full"
          name="permissions"
          label="İzinler"
          initialValue={defaultPermissions}
        >
          <Checkbox.Group options={permissions} />
        </Form.Item>
  </Row>*/}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
