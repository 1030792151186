import {
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Popconfirm,
  Select,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { IOrder, IUserData } from "../../models";
import { FilePdfFilled, DeleteFilled, DownOutlined } from "@ant-design/icons";
import { Notification, NotificationType } from "../../utils/notification";
import {
  RemoveInvoice,
  SetInvoicePrinted,
  SetPhysicalInvoiceReceived,
  UpdatePaymentDate,
  UploadInvoice,
} from "../../utils/request";
import useDataSender from "../../utils/dataSender";
import React from "react";
import { InputFileChooser } from "../input/InputFileChooser";
import useDataFetcher from "../../utils/dataFetcher";
import moment from "moment";
import { isOperator } from "../../utils/permission";

import { PDFExportInvoice } from "../renderer/PDFExportInvoice";
import { lowerCase } from "../../utils/textutils";

import locale from "antd/es/date-picker/locale/tr_TR";

const { Option } = Select;

export interface ArgsTableOrderList {
  userList: IUserData[];
  orders: IOrder[];
  updateOrderList: () => void;
  selectedOrderIDs: React.Key[];
  setSelectedOrderIDs: React.Dispatch<React.SetStateAction<string[]>>;
  global: boolean;
  openCreateOrderForm: (defaultOrder: IOrder, editingOrderId: string) => void;
  removeOrders: (orderIDs: string[]) => void;
}

export const moneyFormatter = new Intl.NumberFormat("tr-TR", {
  style: "currency",
  currency: "TRY",
});

export interface OrderTableEntry extends IOrder {
  key: React.Key;
}

export const TableOrderList: React.FunctionComponent<ArgsTableOrderList> = (
  props: ArgsTableOrderList
) => {
  const { isSending, sendData } = useDataSender();
  const { isFetching, fetchData } = useDataFetcher();

  //const [selectedRowsTotalAmount, setSelectedRowsTotalAmount] = useState(0);
  const [selectedRowsTotalPrice, setSelectedRowsTotalPrice] = useState(0);

  const [invoiceViewOrder, setInvoiceViewOrder] = useState(null as IOrder);

  const [selectedFastSelectionUser, setSelectedFastSelectionUser] = useState<
    string | undefined
  >(undefined);
  const [selectedFastSelectionState, setSelectedFastSelectionState] = useState<
    string | undefined
  >(undefined);
  const [
    selectedFastSelectionPaymentDate,
    setSelectedFastSelectionPaymentDate,
  ] = useState<string | undefined>(undefined);

  function ButtonPhysicalInvoice(physicalInvoice: boolean) {
    return (
      <Tag color={physicalInvoice ? "#87d068" : "#f50"}>
        <span className="font-semibold">
          {physicalInvoice ? "Fatura Var" : "Fatura Yok"}
        </span>
      </Tag>

      /*<Button
        title="Faturanın teslim alınma durumu"
        type="primary"
        danger={!physicalInvoice}
        style={
          physicalInvoice ? { background: "green", borderColor: "green" } : {}
        }
        //icon={<FileZipFilled />}
      >
        {physicalInvoice ? "Fatura Var" : "Fatura Yok"}
      </Button>*/
    );
  }

  const collumns: ColumnsType<OrderTableEntry> = [
    {
      dataIndex: "physicalInvoice",
      key: "physicalInvoice",
      title: "Fatura",
      width: 100,
      render: (physicalInvoice, order) => (
        <>
          {props.global && isOperator() && (
            <Popconfirm
              title={"Değiştiriliyor..."}
              onConfirm={() => {
                fetchData(
                  SetPhysicalInvoiceReceived(order.id, !physicalInvoice),
                  {
                    onResolve(data: []) {
                      props.updateOrderList();
                    },
                    onError(error) {},
                  }
                );
              }}
              okText="Değiştir"
              cancelText="Kapat"
              // okButtonProps={{ disabled: !props.editable }}
            >
              {ButtonPhysicalInvoice(physicalInvoice)}
            </Popconfirm>
          )}

          {!(props.global && isOperator()) &&
            ButtonPhysicalInvoice(physicalInvoice)}
        </>
      ),
    },
    { dataIndex: "orderId", key: "orderId", title: "Sipariş No" },
    { dataIndex: "website", key: "website", title: "Website" },
    { dataIndex: "seller", key: "seller", title: "Satıcı" },
    { dataIndex: "product", key: "product", title: "Ürün" },
    { dataIndex: "color", key: "color", title: "Renk" },
    {
      dataIndex: "paymentType",
      key: "paymentType",
      title: "Ö.T",
      render: (paymentType) => {
        var display = paymentType;
        if (paymentType == "deferred") display = "Vadeli";
        else if (paymentType == "cash") display = "Nakit";
        return display;
      },
    },
    /*{
      dataIndex: "imei",
      key: "imei",
      title: "IMEI",
      render: (imei, order) => (
        <>
          {
            <PopoverWithInpuxBox
              buttonTitle="Görüntüle"
              defaultValue={imei}
              editable={isOperator()}
              onOk={(value) => {
                fetchData(UpdateIMEI(order.id, value), {
                  onResolve(data: []) {
                    props.updateOrderList();
                    new Notification(
                      NotificationType.Success,
                      "IMEI güncellendi"
                    ).send();
                  },
                  onError(error) {
                    new Notification(
                      NotificationType.Error,
                      "IMEI güncellenirken hata oluştu"
                    )
                      .setDuration(2)
                      .send();
                  },
                });
              }}
              icon={<FileSearchOutlined />}
            />
          }
        </>
      ),
    },*/
    { dataIndex: "company", key: "company", title: "Firma" },
    //{ dataIndex: "amount", key: "amount", title: "Adet" },
    {
      dataIndex: "price",
      key: "price",
      title: "Fiyat",
      render: (price) => {
        return (
          <>
            <span className="font-semibold">
              {moneyFormatter.format(price)}
            </span>
          </>
        );
      },
    },
    { dataIndex: "desc", key: "desc", title: "Açıklama", width: 150 },
    {
      dataIndex: "paymentDate",
      key: "paymentDate",
      title: "Vade Tarihi",
      width: 125,
      render: (paymentDate, object) => (
        <div className="flex space-x-1 items-center">
          <DatePicker
            style={{
              minWidth: 105,
            }}
            size="small"
            onChange={(date) => updatePaymentDate(object.id, date)}
            locale={locale}
            format={"DD-MM-YYYY"}
            defaultValue={paymentDate ? moment(paymentDate) : null}
            disabled={!props.global}
          />
        </div>
      ),
    },
    {
      dataIndex: "states",
      key: "states",
      title: "Durum",
      width: 210,
      render: (_arg, object) => (
        <>
          {object.shipment ? (
            <Tag color="#87d068">
              <span className="font-semibold">Teslim Edildi</span>
            </Tag>
          ) : (
            <Tag color="#f50">
              <span className="font-semibold">Teslim Edilmedi</span>
            </Tag>
          )}

          {object.paid ? (
            <Tag color="#87d068">
              <span className="font-semibold">Ödendi</span>
            </Tag>
          ) : (
            <Tag color="#f50">
              <span className="font-semibold">Ödenmedi</span>
            </Tag>
          )}
        </>
      ),
    },
    {
      dataIndex: "invoice",
      key: "invoice",
      title: "Fatura",
      render: (invoice, order) => (
        <>
          {!invoice && (
            <InputFileChooser
              loading={isSending || isFetching}
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  let file = e.target.files[0];
                  const isPDF = file.type === "application/pdf";
                  if (!isPDF) {
                    new Notification(
                      NotificationType.Error,
                      `${file.name} PDF dosyası değil` + file.type
                    )
                      .setDuration(2)
                      .send();
                    return;
                  }
                  const sizeCheck = file.size < 1024 * 1024;
                  if (!sizeCheck) {
                    new Notification(
                      NotificationType.Error,
                      `Dosya boyutu 1 MB'den büyük olamaz`
                    )
                      .setDuration(2)
                      .send();
                    return;
                  }

                  const formData = new FormData();
                  formData.append("file", file);

                  sendData(UploadInvoice(order.id), formData, {
                    onResolve(data) {
                      props.updateOrderList();
                      new Notification(
                        NotificationType.Success,
                        "Fatura eklendi"
                      ).send();
                    },
                    onError(error) {
                      if (error.response && error.response.status == 409) {
                        new Notification(
                          NotificationType.Error,
                          "Bu daha fatura önce yüklenmiş"
                        )
                          .setDuration(2)
                          .send();
                      } else {
                        new Notification(
                          NotificationType.Error,
                          "Fatura yüklenirken hata oluştu"
                        )
                          .setDuration(2)
                          .send();
                      }
                    },
                  });
                }
              }}
            />
          )}

          {invoice && (
            <div className="flex space-x-1">
              <Button
                title="Faturayı görüntüle"
                type="primary"
                icon={<FilePdfFilled />}
                style={
                  order.invoicePrinted
                    ? { background: "green", borderColor: "green" }
                    : {}
                }
                onClick={() => {
                  setInvoiceViewOrder(order);
                }}
              />

              <Popconfirm
                title={"Siliniyor"}
                onConfirm={() => {
                  fetchData(RemoveInvoice(order.id), {
                    onResolve(data: []) {
                      props.updateOrderList();
                      new Notification(
                        NotificationType.Success,
                        "Fatura silindi"
                      ).send();
                    },
                    onError(error) {
                      new Notification(
                        NotificationType.Error,
                        "Fatura silinirken hata oluştu"
                      )
                        .setDuration(2)
                        .send();
                    },
                  });
                }}
                okText="Sil"
                cancelText="Kapat"
              >
                <Button
                  danger
                  type="primary"
                  title="Faturayı sil"
                  icon={<DeleteFilled />}
                />
              </Popconfirm>
            </div>
          )}
        </>
      ),
    },
    {
      dataIndex: "date",
      key: "date",
      title: "Tarih",
      render: (date) => <>{moment(date).format("DD-MM-YYYY HH:mm:ss")}</>,
    },
    {
      dataIndex: "buttons",
      key: "buttons",
      title: "İşlemler",
      render: (_arg, obj) => (
        <>
          {
            <Dropdown
              overlay={
                <Menu
                  onClick={(value) => {
                    if (!value.key) return;

                    if (value.key == "duplicate") {
                      props.openCreateOrderForm(obj, null);
                    } else if (value.key == "edit") {
                      props.openCreateOrderForm(obj, obj.id);
                    } else if (value.key == "delete") {
                      props.removeOrders([obj.id]);
                    }
                  }}
                >
                  <Menu.Item key={"duplicate"}>Benzer Ürün Ekle</Menu.Item>
                  <Menu.Item key={"edit"}>Değiştir</Menu.Item>
                  <Menu.Item key={"delete"}>
                    <span className="text-red-400">Sil</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button>
                <DownOutlined />
              </Button>
            </Dropdown>
          }
        </>
      ),
    },
  ];

  if (props.global) {
    collumns.unshift({
      dataIndex: "userFullName",
      key: "userFullName",
      title: "Kullanıcı",
      render: (userFullName) => {
        return (
          <>
            <span className="font-semibold">{userFullName}</span>
          </>
        );
      },
    });
  }

  function onSelectionChange(selectedIds: string[]) {
    var totalPrice = 0;
    props.orders.forEach((element) => {
      if (selectedIds.includes(element.id)) {
        totalPrice += element.price;
      }
    });
    setSelectedRowsTotalPrice(totalPrice);
  }

  function updateSelections() {
    var orders = Object.assign([], props.orders) as IOrder[];
    if (
      !selectedFastSelectionUser &&
      !selectedFastSelectionState &&
      !selectedFastSelectionPaymentDate
    ) {
      orders = [];
    }

    if (selectedFastSelectionUser) {
      var i = orders.length;
      while (i--) {
        var order = orders[i];

        if (order.user != selectedFastSelectionUser) {
          orders.splice(i, 1);
        }
      }
    }

    if (selectedFastSelectionState) {
      var i = orders.length;
      while (i--) {
        var order = orders[i];

        if (
          !(
            selectedFastSelectionState == "shipped_notpaid" &&
            order.shipment &&
            !order.paid
          )
        ) {
          orders.splice(i, 1);
        }
      }
    }

    if (selectedFastSelectionPaymentDate) {
      var i = orders.length;
      while (i--) {
        var order = orders[i];

        if (
          !order.paymentDate ||
          moment(order.paymentDate).format("DD-MM-YYYY") !=
            selectedFastSelectionPaymentDate
        ) {
          orders.splice(i, 1);
        }
      }
    }

    var newSelectedRowKeys = [] as string[];

    orders.forEach((element) => {
      newSelectedRowKeys.push(element.id);
    });

    props.setSelectedOrderIDs(newSelectedRowKeys);

    onSelectionChange(newSelectedRowKeys);
  }

  function updatePaymentDate(orderId: string, date: any) {
    fetchData(
      UpdatePaymentDate(orderId, date ? date.format("DD-MM-YYYY") : "unset"),
      {
        onResolve(data: []) {
          props.updateOrderList();
          new Notification(NotificationType.Success, "Vade tarihi güncellendi")
            .setDuration(2)
            .send();
        },
        onError(error) {
          new Notification(
            NotificationType.Error,
            "Vade tarihi güncellenirken hata oluştu"
          )
            .setDuration(2)
            .send();
        },
      }
    );
  }

  return (
    <>
      <PDFExportInvoice
        order={invoiceViewOrder}
        onCancel={() => {
          setInvoiceViewOrder(null);
        }}
        onAfterPrint={(info: IOrder) => {
          if (!isOperator()) return;
          fetchData(SetInvoicePrinted(info.id, true), {
            onResolve(data: []) {
              props.updateOrderList();
            },
            onError(error) {},
          });
        }}
      />
      <div className="flex space-x-2">
        {props.global && (
          <Select
            placeholder="Hızlı seçim: Kullanıcı"
            allowClear
            showSearch
            style={{ minWidth: 250 }}
            value={selectedFastSelectionUser}
            onSelect={(selected: any) => {
              setSelectedFastSelectionUser(selected);
            }}
            onDeselect={() => {
              setSelectedFastSelectionUser(undefined);
            }}
            filterOption={(input, option) => {
              return lowerCase(option!.children as unknown as string).includes(
                lowerCase(input)
              );
            }}
            filterSort={(optionA, optionB) => {
              return (optionA!.children as unknown as string)
                .toLowerCase()
                .localeCompare(
                  (optionB!.children as unknown as string).toLowerCase()
                );
            }}
          >
            {props.userList &&
              props.userList.map((item) => (
                <Option key={item.username}>{item.fullName}</Option>
              ))}
          </Select>
        )}
        <Select
          placeholder="Hızlı seçim: Durum"
          allowClear
          showSearch
          style={{ minWidth: 250 }}
          onSelect={(selected: any) => {
            setSelectedFastSelectionState(selected);
          }}
          onDeselect={() => {
            setSelectedFastSelectionState(undefined);
          }}
          filterOption={(input, option) => {
            return lowerCase(option!.children as unknown as string).includes(
              lowerCase(input)
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              );
          }}
        >
          <Option key="shipped_notpaid">{"Teslim Edildi/Ödenmedi"}</Option>
        </Select>
        <DatePicker
          onChange={(date) =>
            setSelectedFastSelectionPaymentDate(
              date ? date.format("DD-MM-YYYY") : undefined
            )
          }
          locale={locale}
          format={"DD-MM-YYYY"}
          disabled={!props.global}
          placeholder="Hızlı Seçim: Vade Tarihi"
          style={{ minWidth: 190 }}
        />
        <Button type="primary" onClick={updateSelections}>
          Hızlı Seç
        </Button>
      </div>
      <Table
        className="mt-2"
        size="middle"
        style={{ overflowX: "auto" }}
        pagination={{ pageSizeOptions: [10, 20, 50, 100, 250, 500] }}
        rowSelection={{
          hideSelectAll: true,
          type: "checkbox",
          selectedRowKeys: props.selectedOrderIDs,
          onChange: (
            newSelectedRowKeys: React.Key[],
            selectedRows: OrderTableEntry[]
          ) => {
            props.setSelectedOrderIDs(newSelectedRowKeys as string[]);
            onSelectionChange(newSelectedRowKeys as string[]);
          },
        }}
        columns={collumns}
        dataSource={props.orders}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  {props.selectedOrderIDs.length}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={4}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={5}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={6}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={7}>{}</Table.Summary.Cell>
                <Table.Summary.Cell index={8}>{}</Table.Summary.Cell>

                {!props.global && (
                  <>
                    <Table.Summary.Cell className="font-semibold" index={9}>
                      {moneyFormatter.format(selectedRowsTotalPrice)}
                    </Table.Summary.Cell>
                  </>
                )}
                {props.global && (
                  <>
                    <Table.Summary.Cell index={9}>{}</Table.Summary.Cell>
                    <Table.Summary.Cell className="font-semibold" index={10}>
                      {moneyFormatter.format(selectedRowsTotalPrice)}
                    </Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
      ></Table>
    </>
  );
};
