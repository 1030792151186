import { Button, Card, Avatar, Modal, Popover } from "antd";
import { SettingOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { IUserData } from "../../models";
import { FormSettings } from "../forms/FormUserSettings";

const { Meta } = Card;

export interface ArgsCardUser {
  user: IUserData;
  deleteListener: (username: string) => void;
  //formNotesFinishListener: (admin: IAdminData, values: []) => void
  formSettingsFinishListener: (user: IUserData, values: []) => void;
}

export const CardUser: React.FunctionComponent<ArgsCardUser> = (
  props: ArgsCardUser
) => {
  // const [isNotesVisible, setNotesVisible] = useState(false);
  const [isSettingsVisible, setSettingsVisible] = useState(false);
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  //const [isLogsVisible, setLogsVisible] = useState(false);

  /*// Notes
    function onFinishFormNotes(admin: IAdminData, values: []) {
        setNotesVisible(false);
        props.formNotesFinishListener(admin, values);
    }*/

  // Settings
  function onFinishFormSettings(admin: IUserData, values: []) {
    setSettingsVisible(false);
    props.formSettingsFinishListener(admin, values);
  }

  return (
    <>
      <Card
        key={"key"}
        actions={[
          //<ProfileOutlined key="notes" onClick={() => setNotesVisible(true)} />,
          <SettingOutlined
            key="setting"
            onClick={() => setSettingsVisible(true)}
          />,
          /*<FileUnknownOutlined
            key="logs"
            onClick={() => setLogsVisible(true)}
          />,*/
          <Popover
            content={
              <>
                <Button onClick={() => setDeleteVisible(false)}>Kapat</Button>{" "}
                <Button
                  type="primary"
                  danger
                  onClick={() => props.deleteListener(props.user.username)}
                >
                  Sil
                </Button>
              </>
            }
            visible={isDeleteVisible}
            onVisibleChange={setDeleteVisible}
            trigger="click"
            title="Siliniyor"
          >
            <DeleteOutlined onClick={() => setDeleteVisible(true)} />
          </Popover>,
        ]}
      >
        <Meta
          avatar={
            <Avatar src="https://icons-for-free.com/iconfiles/png/512/guy+man+user+icon-1320166689116245256.png" />
          }
          title={props.user.username}
        />
      </Card>

      {/*isNotesVisible && (
        <Modal
          title={props.user.username}
          width={720}
          onCancel={() => setNotesVisible(false)}
          closable={true}
          visible={true}
          footer={null}
          centered
        >
          {<FormNotes
            admin={props.user}
            onFinish={(values: any) => onFinishFormNotes(props.admin, values)}
      />}
        </Modal>
      )*/}

      {isSettingsVisible && (
        <Modal
          title={props.user.username}
          // width={720}
          closable={true}
          onCancel={() => setSettingsVisible(false)}
          visible={true}
          footer={null}
          centered
        >
          {
            <FormSettings
              admin={props.user}
              onFinish={(values: any) =>
                onFinishFormSettings(props.user, values)
              }
            />
          }
        </Modal>
      )}

      {/*isLogsVisible && (
        <Modal
          title={props.user.username}
          width={720}
          onCancel={() => setLogsVisible(false)}
          closable={true}
          visible={true}
          footer={null}
          centered
        >
        <TableLogs admin={props.admin} />
        </Modal>
      )*/}
    </>
  );
};
