import { Button, Input, Select } from "antd";
import { useState } from "react";
import { IUserData } from "../models";
import { SearchOutlined } from "@ant-design/icons";

import React from "react";
import { lowerCase } from "../utils/textutils";

const { Option } = Select;

export interface ArgsSearchBarOrderList {
  useUserFilter: boolean;
  userList: IUserData[];
  options: any[];
  loading: boolean;
  onSearch(selectedFilters: string[]): void;
}

export interface SelectOptionEntry {
  key: string;
  displayName: string;
}

export function createSelectOptionEntry(
  key: string,
  displayName: string
): SelectOptionEntry {
  return { key: key, displayName: displayName } as SelectOptionEntry;
}

export const SearchBarOrderList: React.FunctionComponent<
  ArgsSearchBarOrderList
> = (props: ArgsSearchBarOrderList) => {
  const [selectedFilters, setSelectedFilters] = useState([] as string[]);
  //const [productInput, setProductInput] = useState("");

  function createSelectBox(
    filterKey: string,
    placeholder: string,
    listEntries: SelectOptionEntry[],
    minWidth?: number
  ) {
    return (
      <Select
        showSearch
        allowClear
        style={{ minWidth: minWidth ? minWidth : 160 }}
        placeholder={placeholder}
        optionFilterProp="children"
        loading={props.loading}
        onClear={() => {
          removeFilter(filterKey);
        }}
        onSelect={(user: string) => {
          addFilter(filterKey, user);
        }}
        filterOption={(input, option) => {
          return lowerCase(option!.children as unknown as string).includes(
            lowerCase(input)
          );
        }}
        filterSort={(optionA, optionB) => {
          return (optionA!.children as unknown as string)
            .toLowerCase()
            .localeCompare(
              (optionB!.children as unknown as string).toLowerCase()
            );
        }}
      >
        {listEntries &&
          listEntries.map((item) => (
            <Option key={item.key}>{item.displayName}</Option>
          ))}
      </Select>
    );
  }

  function toSelectOptionEntryList(listEntries: any[]) {
    var list = [] as SelectOptionEntry[];
    if (listEntries) {
      listEntries.forEach((element) => {
        if (listEntries == props.userList) {
          list.push({
            key: element.username,
            displayName: element.fullName,
          });
        } else {
          var key = element.key;
          var displayName = element.displayName;

          if (!key) key = element;
          if (!displayName) displayName = element;

          list.push({
            key: key,
            displayName: displayName,
          });
        }
      });
    }
    return list;
  }

  function createPhysicalInvoiceOptionEntryList() {
    var list = [] as SelectOptionEntry[];
    list.push({
      key: "true",
      displayName: "Var",
    });
    list.push({
      key: "false",
      displayName: "Yok",
    });
    return list;
  }

  function addFilter(addKey: string, addValue: string) {
    var obj = [] as string[];
    for (const [key, value] of Object.entries(selectedFilters)) {
      obj[key] = value;
    }
    obj[addKey] = addValue;
    setSelectedFilters(obj);
  }

  function removeFilter(removeKey: string) {
    var obj = [] as string[];
    for (const [key, value] of Object.entries(selectedFilters)) {
      if (key != removeKey) {
        obj[key] = value;
      }
    }
    setSelectedFilters(obj);
  }

  return (
    <div className="flex space-x-2 justify-start">
      <Button
        loading={props.loading}
        disabled={Object.entries(selectedFilters).length == 0}
        onClick={() => {
          props.onSearch(selectedFilters);
        }}
        icon={<SearchOutlined />}
        type="primary"
      />
      {props.useUserFilter &&
        createSelectBox(
          "user",
          "Kullanıcı",
          toSelectOptionEntryList(props.userList)
        )}
      {createSelectBox(
        "physicalInvoice",
        "Fatura",
        createPhysicalInvoiceOptionEntryList()
      )}
      {createSelectBox(
        "website",
        "Website",
        toSelectOptionEntryList(props.options["websites"])
      )}
      {createSelectBox(
        "seller",
        "Satıcı",
        toSelectOptionEntryList(props.options["sellers"])
      )}
      {createSelectBox(
        "product",
        "Ürün",
        toSelectOptionEntryList(props.options["products"]),
        250
      )}
      {/*<Input
        placeholder="Ürün"
        style={{ width: 160 }}
        value={productInput}
        onChange={(e) => {
          setProductInput(e.target.value);
          if (!e.target.value || e.target.value.length == 0) {
            removeFilter("product");
          } else {
            addFilter("product", e.target.value);
          }
        }}
      />*/}
      {createSelectBox(
        "paymentType",
        "Ödeme Türü",
        props.options["paymentType"]
      )}
      {createSelectBox(
        "company",
        "Firma",
        toSelectOptionEntryList(props.options["companies"])
      )}
    </div>
  );
};
