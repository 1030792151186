import { Button, List, Modal } from "antd";
import { useEffect, useState } from "react";
import useDataSender from "../../utils/dataSender";
import useDataFetcher from "../../utils/dataFetcher";
import { IUserData } from "../../models";
import {
  GetAdminList,
  RegisterUser,
  RemoveUser,
  UpdateUser,
} from "../../utils/request";
import { CardUser } from "../../components/card/CardUser";
import { Notification, NotificationType } from "../../utils/notification";
import { FormRegister } from "../../components/forms/FormUserRegister";

export const LayoutUsers: React.FunctionComponent = () => {
  const { isSending, sendData } = useDataSender();
  const { isFetching, fetchData } = useDataFetcher();

  const [isRegisterFormVisible, setRegisterFormVisible] = useState(false);
  const [userList, setUserList] = useState([] as IUserData[]);

  useEffect(() => {
    updateList();
  }, []);

  function updateList() {
    fetchData(GetAdminList(), {
      onResolve(data: []) {
        setUserList(data["content"]);
      },
      onError(error) {},
    });
  }

  function updateAdmin(admin: IUserData, patchData: []) {
    admin["password"] = null;
    for (var key in patchData) {
      var data = patchData[key] as string;
      if (key == "password" && data.length == 0) continue;
      admin[key] = data;
    }

    sendData(UpdateUser(admin.username), admin, {
      onResolve(data) {
        updateList();
        new Notification(NotificationType.Success, "Düzenlendi").send();
      },
      onError(error) {},
    });
  }

  function onUserDelete(username: string) {
    fetchData(RemoveUser(username), {
      onResolve(data) {
        updateList();
        new Notification(NotificationType.Success, "Silindi").send();
      },
      onError(error) {},
    });
  }

  // Form

  function onFinishFormRegister(form: []) {
    setRegisterFormVisible(false);

    sendData(RegisterUser(), form, {
      onResolve(data) {
        updateList();
        new Notification(NotificationType.Success, "Kayıt edildi").send();
      },
      onError(error) {
        if (error.response.status == 409) {
          new Notification(
            NotificationType.Error,
            "Kullanıcı zaten kayıtlı"
          ).send();
        }
      },
    });
  }

  /*function onFinishFormNotes(admin: IUserData, form: []) {
    updateAdmin(admin, form);
  }*/

  function onFinishFormSettings(admin: IUserData, form: []) {
    updateAdmin(admin, form);
  }

  return (
    <>
      <div className="flex pb-4 align-center">
        <Button type="primary" onClick={() => setRegisterFormVisible(true)}>
          Yeni Kullanıcı Ekle
        </Button>
      </div>

      <List
        loading={isFetching || isSending}
        grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
        dataSource={userList}
        renderItem={(item) => (
          <List.Item>
            <CardUser
              user={item}
              deleteListener={onUserDelete}
              //formNotesFinishListener={onFinishFormNotes}
              formSettingsFinishListener={onFinishFormSettings}
            />
          </List.Item>
        )}
      />

      {isRegisterFormVisible && (
        <Modal
          title="Yeni kullanıcı ekle"
          width={240}
          onCancel={() => setRegisterFormVisible(false)}
          closable={true}
          visible={true}
          bodyStyle={{ paddingBottom: 0, paddingTop: 10 }}
          footer={null}
          centered
        >
          <FormRegister onFinish={onFinishFormRegister} />
        </Modal>
      )}
    </>
  );
};
