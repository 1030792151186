import { Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormLogin } from "../../components/forms/FormLogin";
import useDataSender from "../../utils/dataSender";
import { setAccessToken, setRefreshToken } from "../../utils/jwt";
import { Notification, NotificationType } from "../../utils/notification";
import { setPermissions } from "../../utils/permission";
import { AuthLogin } from "../../utils/request";

export const LayoutLogin: React.FunctionComponent = () => {
  const { isSending, sendData } = useDataSender();
  const [navigating, setNavigating] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    sendData(
      AuthLogin(),
      values,
      {
        onResolve(data) {
          setNavigating(true);

          setAccessToken(data["accessToken"]);
          setRefreshToken(data["refreshToken"]);
          setPermissions(data["permissions"]);

          new Notification(
            NotificationType.Success,
            "Giriş başarılı yönlendiriliyorsun..."
          )
            .setDuration(2)
            .send();
          setTimeout(() => {
            setNavigating(false);
            //updatePermissionsIfNeed(true);
            navigate("/");
            window.location.reload();
          }, 2000);
        },
        onError(error) {
          if (error.response) {
            if (error.response.status == 429) {
              new Notification(
                NotificationType.Error,
                "Çok fazla hatalı giriş yaptığın için hesabın bloke edildi."
              )
                .setDuration(3)
                .send();
            } else if (error.response.status == 401) {
              new Notification(
                NotificationType.Error,
                "Kullanıcı adı veya şifre hatalı"
              )
                .setDuration(3)
                .send();
            }
          }
        },
      },
      false
    );
  };

  return (
    <div id="app-login" className="flex">
      <Modal
        title="Giriş yap"
        width={480}
        closable={false}
        visible={true}
        footer={null}
        centered
      >
        <FormLogin onFinish={onFinish} loading={isSending || navigating} />
      </Modal>
    </div>
  );
};
