import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, InputNumber, Table } from "antd";
import React, { useState } from "react";
import { IStock, IStockCategory, IStockProduct } from "../../models";
import { Notification, NotificationType } from "../../utils/notification";
import { getColorSelectOptionEntry } from "../../utils/utils";
import { InputCurrency } from "../input/InputCurrency";
import { moneyFormatter } from "./TableOrderList";

export interface ArgsTableStockList {
  loading: boolean;
  products: IStockProduct[];
  categories: IStockCategory[];
  stocks: IStock[];
  deleteStock(id: string): void;
  editStock(id: string, amount: number, price: number): void;
}

export const TableStockList: React.FunctionComponent<ArgsTableStockList> = (
  props: ArgsTableStockList
) => {
  function getCategoryFilterList() {
    var list = [];
    props.categories.forEach((element) => {
      list.push({
        text: element.displayName,
        value: element.id,
      });
    });
    return list;
  }

  const collumns = [
    {
      key: "action",
      dataIndex: "action",
      title: "",
      width: 125,
      render: (_, record: IStock) => {
        if (isEditing(record)) {
          return (
            <div className="space-x-2">
              <a onClick={() => saveEdit(record)}>Kaydet</a>
              <a onClick={() => edit(null)}>İptal</a>
            </div>
          );
        } else {
          return (
            <div className="space-x-2">
              <a onClick={() => edit(record)}>Düzenle</a>
              <a
                style={{ color: "red" }}
                onClick={() => props.deleteStock(record.id)}
              >
                Sil
              </a>
            </div>
          );
        }
      },
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Miktar",
      width: 125,
      render: (_, record: IStock) => {
        if (isEditing(record)) {
          return (
            <InputNumber
              ref={editingAmountInputRef}
              style={{ maxWidth: 70 }}
              defaultValue={record.amount}
              min={0}
              max={100}
            />
          );
        } else {
          return (
            <div className="flex items-center">
              <Button
                type="text"
                style={{ color: "red" }}
                icon={<MinusCircleFilled />}
                disabled={record.amount <= 0}
                onClick={() =>
                  props.editStock(record.id, record.amount - 1, -1)
                }
              />
              <span
                className="font-bold"
                style={{ color: record.amount > 0 ? "black" : "red" }}
              >
                {record.amount}
              </span>
              <Button
                type="text"
                style={{ color: "green" }}
                icon={<PlusCircleFilled />}
                disabled={record.amount >= 100}
                onClick={() =>
                  props.editStock(record.id, record.amount + 1, -1)
                }
              />
            </div>
          );
        }
      },
    },
    {
      key: "price",
      dataIndex: "price",
      title: "Fiyat",
      width: 125,
      render: (_, record: IStock) => {
        if (isEditing(record)) {
          return (
            <InputCurrency
              style={{ width: 120 }}
              value={priceInputText}
              placeHolder="Birim fiyatı"
              onChange={setPriceInputText}
            />
          );
        } else {
          return moneyFormatter.format(record.price);
        }
      },
    },
    {
      key: "brand",
      dataIndex: "brand",
      title: "Marka",
      width: 125,
      filters: getCategoryFilterList(),
      onFilter: (value: string, record) => {
        if (!record) return;
        var product = getProductById(record.productId);
        if (!product) return false;
        var category = getCategoryById(product.categoryId);
        if (!category) return false;
        return category.id == value;
      },
      render: (_, record: IStock) => {
        if (!record) return;
        var product = getProductById(record.productId);
        if (!product) return;
        var category = getCategoryById(product.categoryId);
        if (!category) return;
        return <>{category.displayName}</>;
      },
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Ürün",
      width: 400,
      render: (_, record: IStock) => {
        if (!record) return;
        var product = getProductById(record.productId);
        if (!product) return;
        return <>{product.displayName}</>;
      },
    },
    {
      key: "color",
      dataIndex: "color",
      title: "Renk",
      render: (_, record: IStock) =>
        getColorSelectOptionEntry(record.color)?.displayName,
    },
  ];

  const [editingKey, setEditingKey] = useState("");
  const [priceInputText, setPriceInputText] = useState<string>();
  const editingAmountInputRef = React.useRef();

  const isEditing = (record: IStock) => record.id === editingKey;

  const edit = (record: IStock) => {
    if (!record) {
      setEditingKey("");
      setPriceInputText(null);
      return;
    }

    setEditingKey(record.id);
    setPriceInputText(record.price as any);
  };

  const saveEdit = (record: IStock) => {
    if (!isEditing(record)) return;

    if (!priceInputText) {
      new Notification(NotificationType.Error, "Birim fiyatı girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    var amount = (editingAmountInputRef.current as any).value as number;
    if (!amount) {
      new Notification(NotificationType.Error, "Ürün miktarı girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    setEditingKey("");
    setPriceInputText(null);

    props.editStock(record.id, amount, priceInputText as any);
  };

  function getProductById(id: string): IStockProduct {
    var product = null as IStockProduct;
    props.products.forEach((element) => {
      if (element.id == id) product = element;
    });
    return product;
  }

  function getCategoryById(id: string): IStockCategory {
    var category = null as IStockCategory;
    props.categories.forEach((element) => {
      if (element.id == id) category = element;
    });
    return category;
  }

  return (
    <div>
      <Table
        loading={props.loading}
        size="small"
        style={{ overflowX: "auto" }}
        pagination={false}
        rowClassName="editable-row"
        columns={collumns}
        dataSource={props.stocks}
      />
    </div>
  );
};
