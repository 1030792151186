import { ReloadOutlined } from "@ant-design/icons";
import { Button, InputNumber } from "antd";
import React, { useState } from "react";
import { IStockProduct } from "../models";
import { Notification, NotificationType } from "../utils/notification";
import {
  COLORS_AS_SELECT_OPTION,
  getColorSelectOptionEntry,
} from "../utils/utils";
import { InputCurrency } from "./input/InputCurrency";
import { ISelectOptionEntry, SelectBasic } from "./select/SelectBasic";

export interface ArgsBarCreateStock {
  products: IStockProduct[];
  onCreate(
    productId: string,
    colorId: string,
    amount: number,
    price: number
  ): void;
  update(): void;
}

export const BarCreateStock: React.FunctionComponent<ArgsBarCreateStock> = (
  props: ArgsBarCreateStock
) => {
  const [selectedProduct, setSelectedProduct] = useState<string>();
  const [priceInputText, setPriceInputText] = useState<string>();
  const [selectedColorId, setSelectedColorId] = useState<string>();
  // props.defaultCreateBarProbs?.color

  const inputRef = React.useRef();

  function products2SelectEntries(): ISelectOptionEntry[] {
    var list = [] as ISelectOptionEntry[];
    props.products.forEach((element) => {
      list.push({
        key: element.id,
        displayName: element.displayName,
      });
    });
    return list;
  }

  // Add
  function onCreate() {
    if (!priceInputText) {
      new Notification(NotificationType.Error, "Birim fiyatı girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    if (!selectedProduct) {
      new Notification(NotificationType.Error, "Ürün seçmelisin")
        .setDuration(2)
        .send();
      return;
    }

    var amount = (inputRef.current as any).value as number;
    if (!amount) {
      new Notification(NotificationType.Error, "Ürün miktarı girmelisin")
        .setDuration(2)
        .send();
      return;
    }

    if (!selectedColorId) {
      new Notification(NotificationType.Error, "Renk seçmelisin")
        .setDuration(2)
        .send();
      return;
    }

    props.onCreate(
      selectedProduct,
      selectedColorId,
      amount,
      priceInputText as any
    );
  }

  return (
    <div className="flex space-x-1">
      <Button onClick={() => props.update()} icon={<ReloadOutlined />} />
      <SelectBasic
        id="products"
        loading={false}
        placeholder="Ürün Seç"
        listEntries={products2SelectEntries()}
        onClear={function (): void {
          setSelectedProduct(null);
        }}
        onSelect={function (selected: ISelectOptionEntry): void {
          setSelectedProduct(selected.key);
        }}
        minWidth={400}
      />
      <SelectBasic
        id="color"
        loading={false}
        placeholder="Renk"
        listEntries={COLORS_AS_SELECT_OPTION}
        //defaultValueId={props.defaultCreateBarProbs?.color}
        onClear={() => {
          setSelectedColorId(null);
        }}
        onSelect={(selected: ISelectOptionEntry) => {
          setSelectedColorId(selected.key);
        }}
      />
      <InputNumber
        ref={inputRef}
        style={{ maxWidth: 70 }}
        defaultValue={1}
        min={1}
        max={100}
      />
      <InputCurrency
        style={{ width: 120 }}
        value={priceInputText}
        placeHolder="Birim fiyatı"
        onChange={setPriceInputText}
      />
      <Button type="primary" onClick={onCreate}>
        Oluştur
      </Button>
    </div>
  );
};
