import { Button, Card, Form, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { FormCreateOrder } from "../../components/forms/FormCreateOrder";
import { ReloadOutlined } from "@ant-design/icons";
import {
  moneyFormatter,
  OrderTableEntry,
  TableOrderList,
} from "../../components/table/TableOrderList";
import { IOrder, IUserData } from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import useDataSender from "../../utils/dataSender";
import { Notification, NotificationType } from "../../utils/notification";
import {
  AddOrder,
  EditOrder,
  GetAdminList,
  GetOptions,
  GetOrder,
  RemoveOrders,
  UpdatePaidState,
  UpdateShipmentState,
} from "../../utils/request";
import {
  createSelectOptionEntry,
  SearchBarOrderList,
} from "../../components/SearchBarOrderList";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import moment from "moment";

export const LayoutMyOrders: React.FunctionComponent<any> = (args: any) => {
  var progress = args["type"];

  const global = progress == "global";

  const [userList, setUserList] = useState([] as IUserData[]);
  const [searchedFilters, setSearchedFilters] = useState([] as string[]);

  const [dataSource, setDataSource] = useState([] as IOrder[]);

  const [newOrderFormVisible, setNewOrderFormVisible] = useState(
    null as string
  );

  const [options, setOptions] = useState([]);

  const [selectedOrderIDs, setSelectedOrderIDs] = useState<React.Key[]>([]);

  const [form] = Form.useForm();

  const { isFetching, fetchData } = useDataFetcher();
  const { isSending, sendData } = useDataSender();

  useEffect(() => {
    updateOrderList();

    fetchData(GetAdminList(), {
      onResolve(data: []) {
        setUserList(data["content"]);
      },
      onError(error) {},
    });
  }, []);

  useEffect(() => {
    var interval = setInterval(() => {
      updateOrderList(searchedFilters);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [searchedFilters]);

  function updateOrderList(filters?: string[]) {
    var search = !global ? "me" : "all";

    if (!filters) filters = searchedFilters;

    if (filters && filters["user"]) {
      search = filters["user"];
    }

    updateOptions();
    fetchData(GetOrder(search, filters), {
      onResolve(data: []) {
        const orderList = [] as OrderTableEntry[];
        data.forEach((element) => {
          orderList.push({
            key: element["id"],
            id: element["id"],
            user: element["user"],
            userFullName: element["userFullName"],
            orderId: element["orderId"],
            website: element["website"],
            seller: element["seller"],
            product: element["product"],
            color: element["color"],
            company: element["company"],
            amount: element["amount"],
            price: element["price"],
            paid: element["paid"],
            shipment: element["shipment"],
            invoice: element["invoice"],
            invoicePrinted: element["invoicePrinted"],
            physicalInvoice: element["physicalInvoice"],
            desc: element["desc"],
            imei: element["imei"],
            paymentType: element["paymentType"],
            paymentDate: element["paymentDate"],
            date: element["date"],
          });
        });
        setDataSource(orderList);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Siparişler yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function updateOptions(callback?: any) {
    fetchData(GetOptions("websites,sellers,products,companies"), {
      onResolve(data: []) {
        data["colors"] = [
          "Beyaz",
          "Bronz",
          "Ela",
          "Gri",
          "Gümüş",
          "Kahverengi",
          "Kırmızı",
          "Lacivert",
          "Mavi",
          "Mor",
          "Pembe",
          "Sarı",
          "Siyah",
          "Turuncu",
          "Sierra Mavi",
          "Yeşil",
          "Uzay Grisi",
          "Prizma Mavi",
        ];
        data["paymentType"] = [
          createSelectOptionEntry("deferred", "Vadeli"),
          createSelectOptionEntry("cash", "Nakit"),
        ];
        setOptions(data);
        if (callback) callback();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function onFormFinish(editingOrderId: string, values: any) {
    setNewOrderFormVisible(null);

    sendData(editingOrderId ? EditOrder(editingOrderId) : AddOrder(), values, {
      onResolve(data) {
        updateOrderList();
        new Notification(
          NotificationType.Success,
          "Sipariş " + (editingOrderId ? "düzenlendi" : "oluşturuldu")
        ).send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Sipariş " +
            (editingOrderId ? "düzenlenirken" : "oluşturulurken") +
            " hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function basicSelectOperation(
    popTitle: string,
    okText: string,
    buttonText: string,
    buttonType: "link" | "text" | "ghost" | "primary" | "default" | "dashed",
    onConfirm: () => void
  ) {
    return (
      <Popconfirm
        title={popTitle.replace("<Count>", "" + selectedOrderIDs.length)}
        onConfirm={() => {
          if (!selectedOrderIDs || selectedOrderIDs.length == 0) return;
          onConfirm();
        }}
        okText={okText}
        cancelText="Kapat"
      >
        <Button
          loading={isFetching || isSending}
          disabled={selectedOrderIDs.length == 0}
          type={buttonType}
          style={
            buttonText == "Özet" && selectedOrderIDs.length > 0
              ? {
                  background: "orange",
                  borderColor: "orange",
                }
              : {}
          }
        >
          {buttonText} ({selectedOrderIDs.length})
        </Button>
      </Popconfirm>
    );
  }

  function openCreateOrderForm(defaultOrder: IOrder, editingOrderId: string) {
    form.resetFields();

    if (defaultOrder) {
      form.setFieldsValue({
        ...defaultOrder,
      });
    }

    updateOptions(() => {
      setNewOrderFormVisible(editingOrderId ? editingOrderId : "create");
    });
  }

  function removeOrders(orderIDs: string[]) {
    fetchData(RemoveOrders(orderIDs), {
      onResolve(data: []) {
        new Notification(NotificationType.Success, "Siparişler silindi").send();

        setSelectedOrderIDs([]);
        updateOrderList();
      },
      onError(error) {
        setSelectedOrderIDs([]);
        new Notification(
          NotificationType.Error,
          "Siparişler silinirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function getSelectedOrderInstances(): IOrder[] {
    var orders = [] as IOrder[];

    dataSource.forEach((element) => {
      if (selectedOrderIDs.includes(element.id)) {
        orders.push(element);
      }
    });

    return orders;
  }

  return (
    <Card>
      <SearchBarOrderList
        useUserFilter={global}
        userList={userList}
        options={options}
        loading={isFetching || isSending}
        onSearch={(selectedFilters: string[]) => {
          setSearchedFilters(selectedFilters);
          updateOrderList(selectedFilters);
          setSelectedOrderIDs([]);
        }}
      />

      <div className="flex my-2 space-x-2">
        <Button
          onClick={() => updateOrderList(searchedFilters)}
          loading={isSending || isFetching}
          icon={<ReloadOutlined />}
        ></Button>

        {!global && (
          <Button
            loading={isFetching || isSending}
            onClick={() => {
              openCreateOrderForm(null, null);
            }}
            type="primary"
          >
            Sipariş Gir
          </Button>
        )}
        {basicSelectOperation(
          "<Count> siparişin özeti çıkartılıyor...",
          "Özet çıkar",
          "Özet",
          "primary",
          () => {
            const head = [
              [
                "Kullanıcı",
                "Sipariş NO",
                "Website",
                "Ürün",
                "Ö.T",
                "Vade Tarihi",
                "Firma",
                "Fiyat",
              ],
            ];
            const data = [];

            var totalPrice = 0 as number;
            getSelectedOrderInstances().forEach((element) => {
              let paymentType = "HATA";
              if (element.paymentType == "deferred") {
                paymentType = "Vadeli";
              } else if (element.paymentType == "cash") {
                paymentType = "Nakit";
              }

              totalPrice += element.price;

              data.push([
                element.userFullName,
                element.orderId,
                element.website,
                element.product,
                paymentType,
                element.paymentDate
                  ? moment(element.paymentDate).format("DD.MM.YYYY")
                  : "",
                element.company,
                moneyFormatter.format(element.price),
              ]);
            });

            const foot = [
              ["", "", "", "", "", "", "", moneyFormatter.format(totalPrice)],
            ];

            const doc = new jsPDF();
            doc.setFont("calibri");

            autoTable(doc, {
              head: head,
              body: data,
              foot: foot,
              styles: {
                font: "calibri",
                fontStyle: "normal",
              },
              /*didDrawCell: (data) => {},*/
            });
            doc.output("dataurlnewwindow");
          }
        )}

        {global && (
          <>
            {basicSelectOperation(
              "<Count> sipariş teslim edildi olarak işaretleniyor...",
              "İşaretle",
              "Teslim Edildi Olarak İşaretle",
              "primary",
              () => {
                fetchData(
                  UpdateShipmentState(selectedOrderIDs as string[], true),
                  {
                    onResolve(data: []) {
                      new Notification(
                        NotificationType.Success,
                        selectedOrderIDs.length + " sipariş işaretlendi."
                      ).send();

                      updateOrderList();
                      setSelectedOrderIDs([]);
                    },
                    onError(error) {
                      new Notification(
                        NotificationType.Error,
                        "Teslim edildi olarak işaretlenirken hata oluştu"
                      )
                        .setDuration(2)
                        .send();
                      updateOrderList();
                      setSelectedOrderIDs([]);
                    },
                  }
                );
              }
            )}

            {basicSelectOperation(
              "<Count> sipariş ödendi olarak işaretleniyor...",
              "İşaretle",
              "Ödendi Olarak İşaretle",
              "primary",
              () => {
                fetchData(UpdatePaidState(selectedOrderIDs as string[], true), {
                  onResolve(data: []) {
                    new Notification(
                      NotificationType.Success,
                      selectedOrderIDs.length + " sipariş işaretlendi."
                    ).send();

                    updateOrderList();
                    setSelectedOrderIDs([]);
                  },
                  onError(error) {
                    new Notification(
                      NotificationType.Error,
                      "Ödendi olarak işaretlenirken hata oluştu"
                    )
                      .setDuration(2)
                      .send();
                  },
                });
              }
            )}

            {basicSelectOperation(
              "<Count> sipariş teslim edilmedi olarak işaretleniyor...",
              "İşaretle",
              "Teslim Edilmedi Olarak İşaretle",
              "dashed",
              () => {
                fetchData(
                  UpdateShipmentState(selectedOrderIDs as string[], false),
                  {
                    onResolve(data: []) {
                      new Notification(
                        NotificationType.Success,
                        selectedOrderIDs.length + " sipariş işaretlendi."
                      ).send();

                      updateOrderList();
                      setSelectedOrderIDs([]);
                    },
                    onError(error) {
                      new Notification(
                        NotificationType.Error,
                        "Teslim edildi olarak işaretlenirken hata oluştu"
                      )
                        .setDuration(2)
                        .send();
                    },
                  }
                );
              }
            )}

            {basicSelectOperation(
              "<Count> sipariş ödenmedi olarak işaretleniyor...",
              "İşaretle",
              "Ödenmedi Olarak İşaretle",
              "dashed",
              () => {
                fetchData(
                  UpdatePaidState(selectedOrderIDs as string[], false),
                  {
                    onResolve(data: []) {
                      new Notification(
                        NotificationType.Success,
                        selectedOrderIDs.length + " sipariş işaretlendi."
                      ).send();

                      updateOrderList();
                      setSelectedOrderIDs([]);
                    },
                    onError(error) {
                      new Notification(
                        NotificationType.Error,
                        "Ödenmedi olarak işaretlenirken hata oluştu"
                      )
                        .setDuration(2)
                        .send();
                    },
                  }
                );
              }
            )}
          </>
        )}

        <Popconfirm
          title={selectedOrderIDs.length + " sipariş siliniyor..."}
          onConfirm={() => {
            if (!selectedOrderIDs || selectedOrderIDs.length == 0) return;

            removeOrders(selectedOrderIDs as string[]);
          }}
          okText="Sil"
          cancelText="Kapat"
        >
          <Button
            loading={isFetching || isSending}
            disabled={selectedOrderIDs.length == 0}
            type="primary"
            danger
          >
            Seçilileri Sil ({selectedOrderIDs.length})
          </Button>
        </Popconfirm>
      </div>

      <TableOrderList
        userList={userList}
        selectedOrderIDs={selectedOrderIDs}
        setSelectedOrderIDs={setSelectedOrderIDs}
        updateOrderList={updateOrderList}
        orders={dataSource}
        global={global}
        openCreateOrderForm={openCreateOrderForm}
        removeOrders={removeOrders}
      />
      <Modal
        title={
          newOrderFormVisible == "create" ? "Yeni Sipariş" : "Siparişi Düzenle"
        }
        visible={newOrderFormVisible != null}
        onCancel={() => {
          setNewOrderFormVisible(null);
        }}
        footer={null}
      >
        <FormCreateOrder
          editingOrderId={
            newOrderFormVisible && newOrderFormVisible != "create"
              ? newOrderFormVisible
              : null
          }
          form={form}
          onFormFinish={onFormFinish}
          options={options}
          closeForm={() => {
            setNewOrderFormVisible(null);
          }}
        />
      </Modal>
    </Card>
  );
};
