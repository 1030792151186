import { ISelectOptionEntry } from "../components/select/SelectBasic";

export const WEEK_DAYS = [
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi",
  "Pazar",
];

export function shuffleList<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function getColorSelectOptionEntry(key: string): ISelectOptionEntry {
  var entry = null as ISelectOptionEntry;
  COLORS_AS_SELECT_OPTION.forEach((element) => {
    if (element.key == key) entry = element;
  });
  return entry;
}

export const COLORS_AS_SELECT_OPTION = [
  {
    key: "white",
    displayName: "Beyaz",
  },
  {
    key: "bronze",
    displayName: "Bronz",
  },
  {
    key: "hazel",
    displayName: "Ela",
  },
  {
    key: "gray",
    displayName: "Gri",
  },
  {
    key: "silver",
    displayName: "Gümüş",
  },
  {
    key: "brown",
    displayName: "Kahverengi",
  },
  {
    key: "red",
    displayName: "Kırmızı",
  },
  {
    key: "navyblue",
    displayName: "Lacivert",
  },
  {
    key: "blue",
    displayName: "Mavi",
  },
  {
    key: "purple",
    displayName: "Mor",
  },
  {
    key: "pink",
    displayName: "Pembe",
  },
  {
    key: "yellow",
    displayName: "Sarı",
  },
  {
    key: "black",
    displayName: "Siyah",
  },
  {
    key: "orange",
    displayName: "Turuncu",
  },
  {
    key: "sierrablue",
    displayName: "Sierra Mavi",
  },
  {
    key: "green",
    displayName: "Yeşil",
  },
  {
    key: "spacegray",
    displayName: "Uzay Grisi",
  },
  {
    key: "prismblue",
    displayName: "Prizma Mavi",
  },
] as ISelectOptionEntry[];
