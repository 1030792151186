import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { BarCreateStock } from "../../components/BarCreateStock";

import { TableStockList } from "../../components/table/TableStockList";
import { IStock, IStockCategory, IStockProduct } from "../../models";
import useDataFetcher from "../../utils/dataFetcher";
import useDataSender from "../../utils/dataSender";
import { Notification, NotificationType } from "../../utils/notification";
import {
  CreateStock,
  DeleteStock,
  EditStock,
  GetStockCategories,
  GetStockList,
  GetStockProducts,
} from "../../utils/request";

export const LayoutStockList: React.FunctionComponent = () => {
  const { isFetching, fetchData } = useDataFetcher();
  const { isSending, sendData } = useDataSender();

  const [stockList, setStockList] = useState<IStock[]>();
  const [products, setProducts] = useState([] as IStockProduct[]);
  const [categories, setCategories] = useState([] as IStockCategory[]);

  useEffect(() => {
    updateDataSources();
  }, []);

  function updateDataSources() {
    fetchData(GetStockList(), {
      onResolve(data: []) {
        setStockList(data);
      },
      onError(error) {
        new Notification(NotificationType.Error, "Stok listesi hata oluştu")
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockProducts(), {
      onResolve(data: []) {
        data.sort((n1: IStockProduct, n2: IStockProduct) => {
          var n1CategoryId = n1.categoryId as string;
          var n2CategoryId = n2.categoryId as string;

          if (!n1CategoryId) n1CategoryId = "1";
          if (!n2CategoryId) n2CategoryId = "1";

          return n1CategoryId.localeCompare(n2CategoryId);
        });

        setProducts(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });

    fetchData(GetStockCategories(), {
      onResolve(data: []) {
        setCategories(data);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategoriler yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  // Stock
  function createStock(
    productId: string,
    colorId: string,
    amount: number,
    price: number
  ) {
    fetchData(CreateStock(productId, colorId, amount, price), {
      onResolve(data) {
        updateDataSources();
        new Notification(NotificationType.Success, "Stok oluşturuldu").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Stok oluşturulurken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function deleteStock(id: string) {
    fetchData(DeleteStock(id), {
      onResolve(data: []) {
        updateDataSources();
        new Notification(NotificationType.Success, "Kategori silindi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Kategori silinirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  function editStock(id: string, amount: number, price: number) {
    fetchData(EditStock(id, amount, price), {
      onResolve(data: []) {
        updateDataSources();
        new Notification(NotificationType.Success, "Stok düzenlendi").send();
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Stok düzenlenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }

  return (
    <Card>
      <div className="flex flex-col space-y-2">
        <BarCreateStock
          products={products}
          onCreate={createStock}
          update={updateDataSources}
        />
        <TableStockList
          loading={isFetching || isSending}
          products={products}
          categories={categories}
          stocks={stockList}
          deleteStock={deleteStock}
          editStock={editStock}
        />
      </div>
    </Card>
  );
};
