import React, { useCallback, useEffect, useRef, useState } from "react";

import * as PDFJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

export interface ArgsPDFRenderer {
  url: string;
}

export const PDFRenderer = React.forwardRef(
  (props: ArgsPDFRenderer, ref: any) => {
    const [pdfRef, setPdfRef] = useState(null as any);
    const canvasRef = useRef();

    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const [currentPage, setCurrentPage] = useState(1);

    const renderPage = useCallback(
      (pageNum, pdf = pdfRef) => {
        pdf &&
          pdf.getPage(pageNum).then(function (page) {
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = canvasRef.current as any;
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
              canvasContext: canvas.getContext("2d"),
              viewport: viewport,
            };
            page.render(renderContext);
          });
      },
      [pdfRef]
    );

    useEffect(() => {
      renderPage(currentPage, pdfRef);
    }, [pdfRef, currentPage, renderPage]);

    useEffect(() => {
      const loadingTask = PDFJS.getDocument(props.url);
      loadingTask.promise.then(
        (loadedPdf) => {
          setPdfRef(loadedPdf);
        },
        function (reason) {
          console.error(reason);
        }
      );
    }, []);

    /* const nextPage = () =>
    pdfRef && currentPage < pdfRef.numPages && setCurrentPage(currentPage + 1);

  const prevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);*/

    return (
      <div ref={ref}>
        <canvas ref={canvasRef}></canvas>
      </div>
    );
  }
);
