import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";

interface InputFileChooserProps {
  loading: boolean;
  onChange: (value: any) => void;
}

export const InputFileChooser = (props: InputFileChooserProps) => {
  const fileInput = React.useRef();

  return (
    <div>
      <Button
        //variant="contained"
        loading={props.loading}
        title="Fatura yükle"
        icon={<UploadOutlined />}
        color="primary"
        onClick={() => {
          var a = fileInput.current as any;
          a.click();
        }}
      ></Button>

      <input
        ref={fileInput}
        accept="application/pdf"
        type="file"
        autoFocus
        onChange={(e) => {
          props.onChange(e);
        }}
        onClick={(event) => {
          (event.target as any).value = null;
        }}
        style={{ display: "none" }}
      />
    </div>
  );
};
