import { Card, List } from "antd";
import React, { useEffect, useState } from "react";

import useDataFetcher from "../../utils/dataFetcher";
import {
  AddCompany,
  AddProduct,
  AddSeller,
  AddWebsite,
  GetOptions,
  RemoveCompany,
  RemoveProduct,
  RemoveSeller,
  RemoveWebsite,
} from "../../utils/request";
import { Notification, NotificationType } from "../../utils/notification";
import {
  ArgsListBasicOption,
  ListBasicOption,
  ListOptionEntry,
} from "../../components/list/ListBasicOption";

export const LayoutAdminSettings: React.FunctionComponent = () => {
  const [dataSource, setDataSource] = useState([] as string[]);
  const [dataSourceWebsite, setDataSourceWebsite] = useState([] as string[]);
  const [dataSourceProducts, setDataSourceProducts] = useState([] as string[]);
  const [dataSourceCompanies, setDataSourceCompanies] = useState(
    [] as string[]
  );

  const { isFetching, fetchData } = useDataFetcher();

  useEffect(() => {
    fetchData(GetOptions("websites,sellers,products,companies"), {
      onResolve(data: []) {
        var websites = data["websites"];
        var sellers = data["sellers"];
        var products = data["products"];
        var companies = data["companies"];

        if (websites) setDataSourceWebsite(websites);
        if (sellers) setDataSource(sellers);
        if (products) setDataSourceProducts(products);
        if (companies) setDataSourceCompanies(companies);
      },
      onError(error) {
        new Notification(
          NotificationType.Error,
          "Ayarlar yüklenirken hata oluştu"
        )
          .setDuration(2)
          .send();
      },
    });
  }, []);

  // Website
  function addWebsite(website: string) {
    fetchData(AddWebsite(website), {
      onResolve(data: []) {
        setDataSourceWebsite(data["content"]);
        new Notification(
          NotificationType.Success,
          "Site " + website + " eklendi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function removeWebsite(entry: ListOptionEntry) {
    fetchData(RemoveWebsite(entry.id), {
      onResolve(data: []) {
        setDataSourceWebsite(data["content"]);
        new Notification(
          NotificationType.Success,
          "Ürün " + entry.displayName + " silindi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  // Product
  function addProduct(product: string) {
    fetchData(AddProduct(product), {
      onResolve(data: []) {
        setDataSourceProducts(data["content"]);
        new Notification(
          NotificationType.Success,
          "Ürün " + product + " eklendi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function removeProduct(entry: ListOptionEntry) {
    fetchData(RemoveProduct(entry.id), {
      onResolve(data: []) {
        setDataSourceProducts(data["content"]);
        new Notification(
          NotificationType.Success,
          "Ürün " + entry.displayName + " silindi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  // Seller
  function addSeller(seller: string) {
    fetchData(AddSeller(seller), {
      onResolve(data: []) {
        setDataSource(data["content"]);
        new Notification(
          NotificationType.Success,
          "Satıcı " + seller + " eklendi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function removeSeller(entry: ListOptionEntry) {
    fetchData(RemoveSeller(entry.id), {
      onResolve(data: []) {
        setDataSource(data["content"]);
        new Notification(
          NotificationType.Success,
          "Satıcı " + entry.displayName + " silindi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  // Company
  function addCompany(company: string) {
    fetchData(AddCompany(company), {
      onResolve(data: []) {
        setDataSourceCompanies(data["content"]);
        new Notification(
          NotificationType.Success,
          "Firma " + company + " eklendi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function removeCompany(entry: ListOptionEntry) {
    fetchData(RemoveCompany(entry.id), {
      onResolve(data: []) {
        setDataSourceCompanies(data["content"]);
        new Notification(
          NotificationType.Success,
          "Firma " + entry.displayName + " silindi"
        )
          .setDuration(2)
          .send();
      },
      onError(error) {
        new Notification(NotificationType.Error, "Hata oluştu")
          .setDuration(2)
          .send();
      },
    });
  }

  function str2ListOptionEntry(list: string[]): ListOptionEntry[] {
    var l = [] as ListOptionEntry[];
    list.forEach((element) => {
      console.log(element);
      l.push({ id: element, displayName: element });
    });
    return l;
  }

  const data = [
    {
      title: "Website",
      data: str2ListOptionEntry(dataSourceWebsite),
      add: addWebsite,
      remove: removeWebsite,
    },
    {
      title: "Satıcılar",
      data: str2ListOptionEntry(dataSource),
      add: addSeller,
      remove: removeSeller,
    },
    {
      title: "Firmalar",
      data: str2ListOptionEntry(dataSourceCompanies),
      add: addCompany,
      remove: removeCompany,
    },
    {
      title: "Ürünler",
      data: str2ListOptionEntry(dataSourceProducts),
      add: addProduct,
      remove: removeProduct,
    },
  ] as ArgsListBasicOption[];

  return (
    <div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item>
            <Card title={item.title}>
              <ListBasicOption {...item} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

/*<Card title="Website">
                <ListWebsites
                  websites={dataSourceWebsite}
                  removeWebsite={removeWebsite}
                  addWebsite={addWebsite}
                />
              </Card>
              <Card title="Satıcılar">
                <ListSellers
                  sellers={dataSource}
                  removeSeller={removeSeller}
                  addSeller={addSeller}
                />
              </Card>
              <Card title="Ürünler">
                <ListProducts
                  products={dataSourceProducts}
                  removeProduct={removeProduct}
                  addProduct={addProduct}
                />
              </Card>
              <Card title="Firmalar">
                <ListCompanies
                  companies={dataSourceCompanies}
                  removeCompany={removeCompany}
                  addCompany={addCompany}
                />
              </Card>*/
